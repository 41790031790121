import { useEffect, useRef, useState } from "react";

// The same useTimeout hook
function useTimeout(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay !== null) {
      const timeoutId = setTimeout(() => savedCallback.current(), delay);
      return () => clearTimeout(timeoutId);
    }
  }, [delay]);
}

// Updated useLockWithTimeout hook
export function useLockWithTimeout(initialValue, timeoutDuration) {
  const [value, setValue] = useState(initialValue);
  const [isLocked, setIsLocked] = useState(false);

  // Function to set the new value and lock it
  const setLockedValue = (newValue) => {
    if (!isLocked) {
      setValue(newValue);
      setIsLocked(true);
    }
  };

  // Unlock after the timeout
  useTimeout(
    () => {
      if (isLocked) {
        setIsLocked(false);
      }
    },
    isLocked ? timeoutDuration : null
  ); // Trigger timeout only when locked

  return [value, setLockedValue, isLocked];
}
