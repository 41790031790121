import getDepthsMaxValue from "./getDepthsMaxValue";

export const depthKeys = [100, 500, 1000];
export const depthSteps = Array(8).fill([]);

const getDepthsSteps = (data, step) => {
  return depthSteps
    .reduce((acc, item, index) => {
      let prev = acc[acc.length - 1];

      if (prev) {
        acc.push(getDepthsMaxValue(data.slice(0, prev.index)));
      } else if (prev === null) {
        acc.push(null);
      } else {
        acc.push(getDepthsMaxValue(data));
      }

      return acc;
    }, [])
    .slice(step);
};

export default getDepthsSteps;
