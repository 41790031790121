import { useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";

import wsConfig from "../../Helpers/wsConfig";
import useInterval from "../../Hooks/useInterval";
import { useLockWithTimeout } from "../../Hooks/useLockWithTimeout";

// const socketUrl = `wss://stream.binance.com:9443/stream?streams=icpusdt@kline_1m`;
// const socketUrl = `wss://stream.binance.com:9443/stream?streams=alpacausdt@aggTrade/alpacausdt@kline_1m`;
const socketUrl = `wss://stream.binance.com:9443/stream?streams=alpacausdt@aggTrade`;

const LIMIT = 50;
const TIMELIMIT = 30 * 1000;
const TRADES_UP_PERCENTAGE = 55;

const Trades = () => {
  const { lastMessage } = useWebSocket(socketUrl, wsConfig);

  const [hits, setHits] = useLockWithTimeout([], 60 * 1000);

  const [data, setData] = useState();

  const [buy, setBuy] = useState([]);
  const [buyPrice, setBuyPrice] = useState([]);
  const [buyAmmount, setBuyAmmount] = useState([]);
  const [buyTimestamp, setBuyTimestamp] = useState([]);

  const cleanUp = () => {
    const currentTimestamp = Date.now() - TIMELIMIT;

    if (buyTimestamp[0] === undefined) return;

    let result = 0;

    for (let index = 0; index < buyTimestamp.length; index++) {
      const element = buyTimestamp[index];

      if (currentTimestamp > element) {
        result++;
      }

      for (let index = 0; index < result; index++) {
        buy.shift();
        buyPrice.shift();
        buyAmmount.shift();
        buyTimestamp.shift();
      }
    }

    if (result) {
      setBuy([...buy]);
      setBuyPrice([...buyPrice]);
      setBuyAmmount([...buyAmmount]);
      setBuyTimestamp([...buyTimestamp]);
    }
  };

  useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data);

      setData(data.data);

      if (buy.length > LIMIT) {
        buy.shift();
        buyPrice.shift();
        buyAmmount.shift();
        buyTimestamp.shift();
      }

      const upDown = data.data.m ? -1 : 1;

      setBuy([...buy, upDown * Number(data.data.q)]);
      setBuyPrice([...buyPrice, Number(data.data.p)]);
      setBuyAmmount([...buyAmmount, upDown]);
      setBuyTimestamp([...buyTimestamp, data.data.T]);

      cleanUp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  useInterval(cleanUp, 1000);

  const sum = Math.floor(buy.reduce((a, b) => a + b, 0));
  const sumUp = Math.floor(buyAmmount.reduce((a, b) => (b > 0 ? a + b : a), 0));
  const length = buy.length;
  const avgPrice = (buyPrice.reduce((a, b) => a + b, 0) / length).toFixed(5);
  const percent = Math.round((sumUp / length) * 100);

  const up = percent > TRADES_UP_PERCENTAGE && length >= LIMIT * 0.9 && sum > 0;

  if (up) {
    setHits([...hits, data]);
  }

  if (!data) {
    return null;
  }

  return (
    <div className="chart">
      <div style={{ color: up ? "green" : "red" }}>
        <p>{data ? Number(data.p).toFixed(5) : ""} PRICE </p>
        <span style={{ fontSize: "10px" }}>{avgPrice} avg price</span>

        <p>{data ? Math.ceil(data.q) : "0"} QUANTITY</p>

        <p style={{ color: length >= LIMIT * 0.9 ? "green" : "red" }}>
          {length} LENGTH
        </p>

        <p style={{ color: sum > 0 ? "green" : "red" }}>{sum} SUM</p>

        <p style={{ color: percent > TRADES_UP_PERCENTAGE ? "green" : "red" }}>
          {percent}% UP
        </p>
        <div>
          <ul>
            {hits.map((item) => {
              return (
                <li key={item.T}>
                  {
                    new Date(item.T)
                      .toISOString()
                      .replace("T", " ")
                      .split(".")[0]
                  }
                  {" - "}
                  {Number(item.p).toFixed(5)}
                  {", "}
                  {Math.ceil(item.q)}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Trades;
