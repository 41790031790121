import { useEffect, useState } from "react";

import "./Chart.css";
import ChartCandle from "./ChartCandle";

import ChartDepths, { ChartDepthsText } from "./ChartDepths";

export const MARGIN = 5;
export const OFFSET = 0.06;

//export const TOP = 0;
export const LEFT = 0;
export const WIDTH = 200;
export const HEIGHT = 200;

export const scaleValue = (value, min, max, height) => {
  value = value - min;
  value = (value / (max - min)) * height;
  return height - value;
};

function Chart({ depths, prices, fraction }) {
  const [gap, setGap] = useState(1000);

  const asksMax =
    Math.max(
      ...depths
        //.slice(0, depths.length - 1)
        .map((depth) =>
          Math.max(
            ...depth.asks
              .map((item) => (item ? item.price : null))
              .reduce((acc, item) => {
                if (item) {
                  acc.push(item);
                }
                return acc;
              }, [])
          )
        )
    ) + OFFSET;

  const bidsMin =
    Math.min(
      ...depths
        //.slice(0, depths.length - 1)
        .map((depth) =>
          Math.min(
            ...depth.bids
              .map((item) => (item ? item.price : null))
              .reduce((acc, item) => {
                if (item) {
                  acc.push(item);
                }
                return acc;
              }, [])
          )
        )
    ) - OFFSET;

  const price = prices[prices.length - 1];

  const middleDepth =
    depths[0].bids[0].price +
    (depths[0].asks[0].price - depths[0].bids[0].price) / 2;

  const yMax = Math.ceil(asksMax * fraction) / fraction;
  const yMin = Math.floor(bidsMin * fraction) / fraction;

  const xStep = (WIDTH - MARGIN) / prices.length;
  const ySpace = Math.round((yMax - yMin) * fraction) / fraction;
  const yStep = 1 / fraction / 2;

  const yRules = [];
  for (let index = 0; index < ySpace / yStep + 1; index++) {
    yRules.push(index * yStep);
  }

  const pricesLine = `M ${prices
    .map((item, index) => {
      return `${xStep * index},${scaleValue(item, yMin, yMax, HEIGHT)}`;
    })
    .join(" L ")}`;
  /*
  useEffect(() => {
    if (price && bids) {
      const gapNow = price - bids;

      if (gap < 0.032) {
        console.log(
          "GAP",
          "price:",
          price,
          "bids",
          bids,
          "asks",
          asks,
          "gap",
          gapNow,
          new Date().toISOString()
        );
        setGap(gapNow);
      }
    }
  }, [gap, price, bids]);
*/
  return (
    <div className="chart">
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="xMidYMid meet"
        className="chart-content"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      >
        {yRules.map((item, index) => {
          const value = item + yMin;
          const pos = scaleValue(item + yMin, yMin, yMax, HEIGHT);

          return (
            <g key={index}>
              <text
                x={MARGIN / 2}
                y={pos - MARGIN / 2}
                fontSize="6"
                fill="gray"
                textAnchor="start"
              >
                {value.toFixed(2)}
              </text>

              <line
                x1={LEFT}
                x2={WIDTH}
                y1={pos}
                y2={pos}
                stroke="gray"
                strokeWidth="0.2"
              />
            </g>
          );
        })}

        <ChartDepths depths={depths} yMin={yMin} yMax={yMax} />

        <line
          x1={0}
          x2={WIDTH - MARGIN * 0.5}
          y1={scaleValue(middleDepth, yMin, yMax, HEIGHT)}
          y2={scaleValue(middleDepth, yMin, yMax, HEIGHT)}
          opacity="1"
          stroke="yellow"
          strokeWidth="0.1"
        />

        <text
          x={WIDTH - MARGIN}
          y={scaleValue(price, yMin, yMax, HEIGHT) - MARGIN}
          fontSize="6"
          fill="white"
          textAnchor="end"
        >
          {price}
        </text>

        {depths && (
          <g>
            <ChartDepthsText
              x={15}
              y={12}
              fill="red"
              data={depths[depths.length - 1].asks}
              textAnchor="start"
              valueKey="price"
            />{" "}
            <ChartDepthsText
              x={15}
              y={24}
              fill="white"
              data={depths[depths.length - 1].asks}
              textAnchor="start"
              valueKey="volumeUpTo"
            />
            <ChartDepthsText
              x={15}
              y={36}
              fill="yellow"
              data={depths[depths.length - 1].asks}
              textAnchor="start"
              valueKey="index"
            />
            <ChartDepthsText
              x={15}
              y={HEIGHT}
              fill="green"
              data={depths[depths.length - 1].bids}
              textAnchor="start"
              valueKey="price"
            />
            <ChartDepthsText
              x={15}
              y={HEIGHT - 12}
              fill="white"
              data={depths[depths.length - 1].bids}
              textAnchor="start"
              valueKey="volumeUpTo"
            />
            <ChartDepthsText
              x={15}
              y={HEIGHT - 24}
              fill="yellow"
              data={depths[depths.length - 1].bids}
              textAnchor="start"
              valueKey="index"
            />
          </g>
        )}

        <path stroke="blue" strokeWidth="0.5" d={pricesLine} fill="none" />

        <ChartCandle data={prices} yMin={yMin} yMax={yMax} />
      </svg>
    </div>
  );
}

export default Chart;
