import "./App.css";

import ZingChartComponent from "./Components/ZingChart/ZingChartComponent";
import Candle from "./Components/Candle/Candle";
import Chart from "./Components/Chart/Chart";
import Trades from "./Components/Trades/Trades";

function App() {
  return (
    <div className="App">
      {/* <ZingChartComponent /> */}
      <Trades />
    </div>
  );
}

export default App;
